export const FEED_POST_MEDIA_BASE_FRAGMENT = `
    fragment feedPostMediaBaseFragment on FeedPostMedia {
        uid
        type
        mediaFileResource {
          schemaCode
          path
          uid
          fileType
          fileName
        }
    }
  `;
